html {
  font-size: 10px;
}

p {
  font-size: 1rem;
  margin-bottom: -10px !important;
}

.title {
  margin-top: 0px;
  margin-left: 0px;
  padding-bottom: 40px;
  justify-content: center !important;
  text-align: left;
}

small {
  font-weight: lighter;
  color: gray;
  margin: 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.loan-calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 3rem 2rem;
  border-radius: 0.5rem;

  margin: 10vh, auto;
  background-color: rgb(65, 55, 145);
  opacity: 0.8;
  box-shadow: 15px 20px 70px -10px;
}

.repayment {
  padding-top: 2rem;
  font-size: large;
}

.input-range__label--max {
  display: none;
}

.input-range__label--min {
  display: none;
}

.input-range__label-container {
  left: -50%;
  position: relative;
  display: none;
}

@media screen and (max-width: 960px) {
  .loan-calculator {
  }
}
