/* PromoPage.css */
.promo-page {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
}

.promo-header {
  text-align: center;
  margin-bottom: 20px;
}

.promo-header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.promo-benefits {
  margin-bottom: 30px;
}

.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.benefit {
  flex: 1;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-size: cover;
}

.benefit:nth-child(odd) {
  background: linear-gradient(to bottom right, #448aff, #2979ff);
}

.benefit:nth-child(even) {
  background: linear-gradient(to bottom right, #ff7043, #ff5722);
}

.benefit h3 {
  font-size: 1.8rem;
  margin-top: 0;
}

.benefit p {
  margin: 10px 0;
  font-size: 12px;
  line-height: normal;
}

.btn-signup {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.6rem;
  transition: background-color 0.3s ease;
}

.btn-signup:hover {
  background-color: #ff7043;
}

.promo-process {
  margin-bottom: 30px;
}

.promo-contact {
  text-align: center;
}
