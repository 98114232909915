.membership-container {
  text-align: center;
  padding: 20px;
}

.description {
  margin-top: 20px;
  font-size: 18px;
}

.membership-options {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: 40px 40px;
}

.membership-option {
  flex: 1;
  cursor: pointer;
  transition: transform 0.2s;
}

.membership-card {
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 20px;
  transition: background-color 0.2s;
  height: 500px;
}

.membership-description {
  margin-top: 20px;
  font-size: 14px;
}

.gold {
  background: linear-gradient(to bottom right, #ffc107, #ff9800);
}

.silver {
  background: linear-gradient(to bottom right, #b0bec5, #78909c);
}

.bronze {
  background: linear-gradient(to bottom right, #8d6e63, #5d4037);
}

.selected {
  transform: scale(1.05);
}

.fee {
  font-size: 24px;
  margin-top: 10px;
}

.benefits {
  margin-top: 20px;
  font-size: 30px !important;
  line-height: 30px;
}
