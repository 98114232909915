@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
.forms {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: #e3eef7;
  color: #7b2a8d;
}

.contact {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: #3396e7;
  color: #fff;
}

.contact-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
}
.contact-form {
  flex: 1;
  margin: 20px 50px 20px 0;
}
.map {
  flex: 1;
}

.about {
  background: linear-gradient(
      0deg,
      rgba(17, 17, 17, 0.6),
      rgba(66, 66, 66, 0.6)
    ),
    url("./assets/team.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  align-items: center;
  justify-content: space-around;
  font-size: 2rem;
  background-color: #3396e7;
  color: #fff;
  padding: 4rem;
}

.team-image {
  background: linear-gradient(
      0deg,
      rgba(17, 17, 17, 0.6),
      rgba(66, 66, 66, 0.6)
    ),
    url("./assets/team.png");
  background-size: cover;

  flex: 1;
  margin: 2rem;
  box-shadow: 15px 20px 70px -10px;
}
.team-desc {
  margin: 2rem;
  flex: 3;
}

.values {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: url("./assets/back-services.svg");
  background-size: 100%;

  padding: 20px;
  color: white;
}
.value-list {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.value {
  backgroundposition: "center";
  backgroundsize: "stretch";
  backgroundrepeat: "no-repeat";

  width: 30%;
  border: solid 1px rgb(90, 85, 85);
  font-size: 3rem;
  color: white;
  padding: 3rem;
  flex: 1 1 200px;
}

.value1 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(66, 66, 66, 0.3)
  );
}

.value2 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(66, 66, 66, 0.3)
  );
}

.value3 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(66, 66, 66, 0.3)
  );
}

.value-type {
  margin-top: 1rem;
  font-size: x-large;
}
.value-desc {
  margin-top: 4rem;
  font-size: large;
}

.home {
  background-image: url("./assets/background1.jpg");
  backgroundposition: "center";
  backgroundsize: "cover";
  backgroundrepeat: "no-repeat";
  width: "100vw";
  height: "100vh";
  padding: 4rem;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  justify-content: space-around;
  align-items: center;
  font-size: 2rem;
  background-color: #3396e7;
  color: #fff;
}

.tag1 {
  padding-top: 0%;
  font-size: x-large;
}

.tag2 {
  padding-top: 20px;
  font-size: medium;
}
/* .loan-calculator {
  margin: 30px;
  background-color: #3396e7;
  display: flex;
  justify-content: center;
  color: white;
} */

.tags {
  flex: 3;
  margin-right: 20px;
}
.calculator {
  flex: 1 1 200px;
  margin-left: 20px;
}

.services {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px 20px;
  background: url("./assets/back-services.svg");
  background-size: 100%;
  flex-direction: column;
  gap: 20px;
}

.loan {
  flex: 1;
  font-size: 3rem;
  box-shadow: #3396e7;
  color: white;
  padding: 2rem 4rem;
  width: 75%;
  align-self: center;
}

.loan-type {
  margin-top: 10px;
  font-size: x-large;
  font-weight: bold;
  color: rgb(222, 141, 112);
}
.loan-desc {
  margin-top: 2rem;
  font-size: medium;
}

.loan1 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(56, 49, 53, 0.3)
  );
}

.loan2 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(56, 49, 53, 0.3)
  );
}

.loan3 {
  background: linear-gradient(
    0deg,
    rgba(113, 106, 110, 0.3),
    rgba(56, 49, 53, 0.3)
  );
}

.apply-button {
  font-size: 1.7rem !important;
  background: linear-gradient(
    40deg,
    rgba(246, 6, 142, 0.3),
    rgba(177, 167, 173, 0.3)
  );
}
.apply-button:hover {
  color: #b4e8ee;
}

.page-icon {
  margin-left: 1rem;
  font-size: 100px;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 960px) {
  .home,
  .about,
  .contact {
    font-size: 3.5rem;
  }

  .page-icon {
    margin-left: 1rem;
    font-size: 80px;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
