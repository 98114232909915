* {
  font-size: large;
}

.form-label {
  font-size: 2rem;
}

.form-control {
  font-size: 2rem;
}
