.main-footer {
  color: white;
  background-color: #181c43;
  font-size: 1.2rem;
  bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.col {
  font-size: 1.2rem;
  display: flex;

  justify-content: center;
  align-items: center;

  flex: 1;
}
.icon {
  padding-right: 15px;
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .col {
    justify-content: flex-start;
  }
  .row {
    flex-direction: column;
  }
}
